<template>
  <BodySimple>
    <template #header>
      <h2 class="text-lg font-bold">
        <img src="@/assets/images/business-startup.png" class="h-20 inline-block" />
        تبلیغات
      </h2>
    </template>
    <template #default>
      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-event-96.png" class="inline-block h-20" />
          آمار روزانه تبلیغات گروه ها
        </div>
        <div class="grid sm:grid-cols-3 2xl:grid-cols-4 gap-3">
          <div
            v-for="day in daily"
            :key="day.date"
            class="border bg-blueGray-50 border-t-8 p-3 rounded"
            :class="{
              'border-teal-500': day.status == 'end',
              'border-sky-500': day.status == 'run',
              'border-blueGray-300': day.status == 'wait',
            }"
          >
            <div class="flex mb-3">
              <div class="w-auto flex-shrink-0">{{ day.fa }}</div>
              <div class="w-full text-left" dir="ltr">{{ day.date }}</div>
            </div>
            <div class="flex items-center">
              <div class="w-full">بازدید {{ $number.format(day.views) }}</div>
              <Progress
                class="w-full"
                :percent="(day.views * 100) / groupAd.view_limit"
              />
            </div>
            <div class="flex items-center">
              <div class="w-full">کلید {{ day.keys }}</div>
              <Progress class="w-full" :percent="(day.keys * 100) / groupAd.key_limit" />
            </div>
            <Button
              :to="{ name: 'AdminAdsDay', params: { day: day.date } }"
              class="mt-3 h-9"
              color="teal"
            >
              بیشتر
            </Button>
          </div>
        </div>
      </div>
      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-schedule-96.png" class="inline-block h-20" />
          آخرین سفارش ها
        </div>
        <div class="grid sm:grid-cols-2 2xl:grid-cols-3 gap-3">
          <div
            v-for="pay in lasts"
            :key="pay.id"
            class="border bg-blueGray-50 border-t-4 border-blueGray-300 p-3 rounded"
          >
            <div class="flex mb-3">
              <div class="w-full">
                {{ pay.created_at.fa }} ساعت {{ pay.created_at.time }}
              </div>
              <div class="w-full text-left">{{ pay.created_at.diff }}</div>
            </div>
            <div class="py-1">
              شروع:
              {{
                pay.adGroup?.firstView?.date?.fa ?? pay.adTarget?.firstSend?.send_at?.fa
              }}
            </div>
            <div class="flex items-center py-1">
              <div class="w-full">
                {{ pay.user?.first_name }} {{ pay.user?.last_name }}
              </div>
              <div class="w-full" dir="ltr">@{{ pay.user?.username }}</div>
            </div>
            <div class="flex items-center py-1">
              <div class="w-full">{{ pay.adGroup ? "🚀 سراسری" : "🎯 هدفمند" }}</div>
              <div class="w-full text-left" v-if="pay.adGroup">
                {{ $number.format(pay.amount) }} تومان
              </div>
            </div>
            <Button
              :to="{ name: 'AdminBot', params: { botId: 123 } }"
              class="
                mt-3
                text-sm
                h-9
                lg:text-lg
                bg-teal-500
                hover:bg-teal-600
                active:bg-teal-700
                text-teal-50
              "
            >
              بیشتر
            </Button>
          </div>
        </div>
      </div>
      <div class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5">
        <div class="text-lg font-bold">
          <img src="@/assets/images/icons8-date-span-96.png" class="inline-block h-20" />
          سفارش‌های بلند مدت
        </div>
        <div class="grid sm:grid-cols-2 2xl:grid-cols-3 gap-3">
          <div class="border bg-blueGray-50 border-t-4 border-teal-500 p-3 rounded">
            <div class="flex mb-3">
              <div class="w-full">میلاد نظری</div>
              <div class="w-full" dir="ltr">@masfasd</div>
            </div>
            <div class="py-1">شروع: سه شنبه 11 آبان 11:00 ب.ظ (2 روز دیگر)</div>
            <div class="py-1">شروع: سه شنبه 11 آبان 11:00 ب.ظ (2 روز دیگر)</div>
            <Progress :percent="60" />
            <div class="flex items-center py-1">
              <div class="w-full">25 هزار بازدید</div>
              <div class="w-full text-left">0 کلید</div>
            </div>
            <Button
              :to="{ name: 'AdminBot', params: { botId: 123 } }"
              class="
                mt-3
                text-sm
                h-9
                lg:text-lg
                bg-teal-500
                hover:bg-teal-600
                active:bg-teal-700
                text-teal-50
              "
            >
              بیشتر
            </Button>
          </div>
          <div
            v-for="index in 8"
            :key="index"
            class="border bg-blueGray-50 border-t-4 border-blueGray-300 p-3 rounded"
          >
            <div class="flex mb-3">
              <div class="w-full">میلاد نظری</div>
              <div class="w-full" dir="ltr">@masfasd</div>
            </div>
            <div class="py-1">شروع: سه شنبه 11 آبان 11:00 ب.ظ (2 روز دیگر)</div>
            <div class="py-1">شروع: سه شنبه 11 آبان 11:00 ب.ظ (2 روز دیگر)</div>
            <Progress :percent="60" />
            <div class="flex items-center py-1">
              <div class="w-full">25 هزار بازدید</div>
              <div class="w-full text-left">0 کلید</div>
            </div>
            <Button
              :to="{ name: 'AdminBot', params: { botId: 123 } }"
              class="
                mt-3
                text-sm
                h-9
                lg:text-lg
                bg-teal-500
                hover:bg-teal-600
                active:bg-teal-700
                text-teal-50
              "
            >
              بیشتر
            </Button>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import Progress from "@/components/Progress.vue";
import Button from "@/components/Button.vue";
export default {
  components: { BodySimple, Progress, Button },
  data() {
    return {
      daily: [],
      groupAd: [],
      lasts: [],
      longs: [],
    };
  },
  mounted() {
    this.getDaily();
    this.getLasts();
  },
  methods: {
    getDaily: async function () {
      try {
        const response = await this.$axios.get("api/admin/ads/dailyGroupAds");
        // console.log(response);
        this.daily = response.data.daily;
        this.groupAd = response.data.group_ad;
      } catch (error) {
        console.error(error);
      }
    },
    getLasts: async function () {
      try {
        const response = await this.$axios.get("api/admin/ads/lastAdsOrders");
        // console.log(response);
        this.lasts = response.data.lasts;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>
