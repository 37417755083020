<template>
  <div class="bg-blueGray-700 rounded-full h-2 p-0.5">
    <div
      class="bg-teal-500 h-full rounded-full float-left"
      :style="{ width: (percent > 100 ? 100 : percent) + '%' }"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    percent: {
      type: Number,
      default: 0,
    },
  },
};
</script>
